<template>
  <div class="row">
    <div class="col-md-4">
      <label> {{ name }} </label>
    </div>
    <div class="col-md-8">
      <apg-dropdown
        :valueField="valueField ?? table + '_id'"
        :table="table"
        :descriptionField="descriptionField ?? table?.substring(0,table.indexOf('_',table)) + '_name'"
        :showDefaultValue="showDefaultValue ?? true"
        defaultEmptyValue="0"
        :defaultEmptyValueMessage="defaultEmptyValueMessage ?? 'Please choose value'"
        idx="0"
        :listType="listType"
        type="Regular"
        @DropdownChanged="processDropdown"
        :disabled="disabled"
        :help="help"
      />
    </div>
  </div>
</template>

<script>
import ApgDropdown from '@/components/shared/lists/apg-dropdown'
import {useStore} from 'vuex'

export default {
  name: 'AttributeDropdown',
  components: {ApgDropdown},
  props: {
    id: String,
    value: String,
    name: String,
    attId: Number,
    disabled: Boolean,
    attInfoId: Number,
    ownerId: Number,
    listType: String,
    table: String,
    AutoSave: Boolean,
    valueField: String,
    descriptionField: String,
    showDefaultValue: Boolean,
    defaultEmptyValueMessage: String,
    help: String
  },
  setup(props) {
    const store = useStore();

    // make sure if we already have a value that it is selected
    store.commit('apgList/setDropdownValue',  {
      idx:0,
      table:props.table,
      listType: props.listType,
      data: props.value
    });

    function processDropdown(val) {
      console.log('Child component ApgDropdown emitted event DropdownChanged: ' + val)
      // val is zero when we're on the selection message
      if (val == 0) {
        return;
      }
      store.dispatch('user/upsertAttribute', {
        attribute_value: val,
        attribute_info_id: props.attInfoId,
        owner_id: props.ownerId,
        listType: props.listType
      });
    }

    return {
      processDropdown
    }
  },
}
</script>
