import axios from 'axios'
import { useToast } from 'vue-toastification';

const toast = useToast();

const getBookmarkedSensor = (state, sensorId) => {
  return state.bookmarkedSensors.find(sensor => sensor.sensor_id === sensorId);
};

export const user = {
  namespaced: true,
  state: {
    url_list: 'users',
    url_users_for_group: 'users/group',
    url_user: 'user/info',
    url_profile: 'user/profile',
    url_add_users_to_group: 'group/add_users/',
    current_user_id: 0,
    url_remove_user_from_group: 'user/remove_group/',
    url_upsert_attribute: 'upsert_attribute',
    url_notification_test: 'test/',
    url_invite: 'user/invite/',
    user_info: [],
    invite_email: '',
    invite_user_modal_shown: false,
    invite_lock_status: undefined,
    bookmarkedSensors: [],
    lastReadings: [],
    lastReadingsLoading: false,
    bookmarkedReadings: [],
    bookmarkedReadingsLoading: false,
    communicationCounts: [],
  },

  mutations: {
    toggleAddUserModal(state, payload) {
      state.invite_email = '';
      state.invite_user_modal_shown = payload;
    },
    setInviteEmail(state, payload) {
      state.invite_email = payload;
    },
    setBookmarks(state, payload) {
      state.bookmarkedSensors = payload;
    },
    deleteBookmark(state, sensorId) {
      let bookmark = getBookmarkedSensor(state, sensorId);
      if (bookmark != null) bookmark.deleted = true;
    },
    addBookmark(state, sensorId) {
      let bookmark = getBookmarkedSensor(state, sensorId);
      if (bookmark?.deleted == true) {
        bookmark.deleted = false;
        return;
      }
      // only add if not already bookmarked
      state.bookmarkedSensors.push({
        sensor_id: sensorId,
        deleted: false
      });
    },
  },
  getters: {
    bookmarkedSensors: state => {
      if (state.bookmarkedSensors.length === 0) {
        this.dispatch('getBookmarkedSensors');
      } else {
        return state.bookmarkedSensors;
      }
    }
  },
  actions: {
    async upsertAttribute({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_upsert_attribute;

      if (payload.attribute_value == null || payload.attribute_info_id == null || payload.owner_id == null) {
        console.log(`Invalid attribute data:`, payload);
        toast.error('Invalid attribute data. Please try again.');
        return;
      }

      try {
        let rs = await axios.post(url, {
          attribute_value: payload.attribute_value,
          attribute_info_id: payload.attribute_info_id,
          owner_id: payload.owner_id,
        });
        console.log('upsertAttribute', rs);
        commit('apgList/setList', {listType: payload.listType, data: rs.data}, {root: true});
        toast.success('Attribute updated!');
      } catch (err) {
        console.error('Failed to update attribute ', err);
        toast.error('Unable to update attribute. Please try again later.');
      }
    },

    async findAll ({ rootState, state, commit }, payload) {
      let url = rootState.settings.api_base + state.url_list

      try {
        let rs = await axios.get(url)
        commit('apgList/setList', {listType: payload , data: rs.data}, {root: true})
      } catch (err) {
        console.error('Failed to load user list.  Error was ')
        console.error({ err })
      }
    },

    async findProfile({rootState, state, commit }, payload) {
      let user_id = rootState.auth.user.user.Auth.system_user_id;
      let url = rootState.settings.api_base + state.url_profile + '/' + user_id;
      console.log(url);

      try {
        let rs = await axios.get(url);
        console.log('profile info is', rs);
        commit('apgList/setList', {listType: payload.listType, data: rs.data.profileAttrs}, {root: true});
      } catch (err) {
        console.error('Failed to load user profile info: ', err);
      }
    },

    async findOne ({ rootState, state, commit },payload) {
      // clear old attributes
      rootState.apgList.List.user_attribute = false;

      let user_id = rootState.apgList.ids['user'].user_id;
      let requester_id = rootState.auth.user.user.Auth.system_user_id;
      let url = rootState.settings.api_base + state.url_user + '/' + user_id + '/' + requester_id;
      console.log(url)

      try {
        let rs = await axios.get(url)
        console.log('user info is', rs)
        commit('apgList/setList', {listType: payload.listType ,data: rs.data.Attributes}, {root: true})
        commit('status_bar/setStatus', rs.data.status,{ root: true })
      } catch (err) {
        console.error('Failed to load user info.  Error was ')
        console.error({ err })
      }
    },
    async findAllForGroup ({ rootState, state, commit }, payload) {
      let url = rootState.settings.api_base + state.url_users_for_group + '/' + rootState.apgList.ids['group'].group_id

      try {
        let rs = await axios.get(url)
        console.log('found user list for group', rs.data)
        commit('apgList/setLists', {listType: payload.listType ,data: rs.data}, {root: true})
      } catch (err) {
        console.error('Failed to load user list for group.  Error was ',err)
      }
    },
    async removeGroupFromUser({commit, rootState, state}, payload) {
      let url = rootState.settings.api_base + state.url_remove_user_from_group + rootState.apgList.ids['user'].user_id +
        "/" + rootState.apgList.ids[payload.listType].group_id
      console.log('removing user url is',url) 
      console.log('payload is ',payload)
      try {
        let rs = await axios.delete(url);
        console.log('rs is :',rs);
        commit('apgList/setLists', {listType: payload.listType ,data: rs.data}, {root: true});
        toast.success('Removed user from group!');
      } catch(err) {
        console.error('Failed to remove user from group.  Error was ');
        console.error({ err });
        toast.error('Unable to remove user from group. Please try again later.');
      }
    },
    async addUsersToGroup({state, commit, dispatch, rootState}, payload) {
      let checked = rootState.apgList.checked[payload.listType];
      if (!checked || checked.length === 0) {
        return;
      }

      let user_list = checked.join('-');
      let user_str = checked.length > 1 ? 'users' : 'user';
      console.log('user list is ', user_list)
      let url = rootState.settings.api_base + state.url_add_users_to_group + rootState.apgList.ids['group'].group_id +
        "/" + user_list;
      console.log('url ', url);
      try {
        let rs = await axios.put(url, {});
        console.log('addUsersToGroup', rs);
        commit('apgList/clearChecked', payload, {root: true});
        dispatch('findAllForGroup', payload);
        toast.success(`Added ${user_str} to group!`);
      } catch (err) {
        console.log("addUsersToGroup: error was: ", err);
        toast.error(`Unable to add ${user_str} to group. Please try again later.`);
      }
    },
    async sendTestNotification({state, rootState}, payload) {
      let url = rootState.settings.api_base +
        state.url_profile + '/' +
        state.url_notification_test +
        payload.test_type + '/' +
        payload.user_id;
      console.log('url ', url);
      let res;
      try {
        res = await axios.put(url, {});
      } catch (err) {
        console.error('sendTestNotification', err);
      }
      return res;
    },

    async getLastReadings({state, rootState}) {
      state.lastReadingsLoading = true;
      let current_user_id = rootState.auth.user.user.Auth.system_user_id;
      let url = rootState.settings.api_base + 'user/last_readings/' + current_user_id;
      try {
        let res = await axios.get(url);
        state.lastReadings = res.data;
      } catch (err) {
        console.error('getLastReadings error:', err);
        toast.error('Unable to fetch last readings. Please try again later.');
      }
      state.lastReadingsLoading = false;
    },

    async fetchBookmarkedReadings({rootState, state}) {
      state.bookmarkedReadingsLoading = true;
      let url = rootState.settings.api_base + 'bookmarks';
      try {
        let res = await axios.get(url);
        state.bookmarkedReadings = res.data;
      } catch (e) {
        console.error('fetchBookmarkedReadings error:', e);
        toast.error('Unable to fetch bookmarked readings. Please try again later.');
      }
      state.bookmarkedReadingsLoading = false;
    },

    async fetchCommunicationCounts({rootState, state}) {
      let current_user_id = rootState.auth.user.user.Auth.system_user_id;
      let url = rootState.settings.api_base + 'user/commUsage/' + current_user_id;
      try {
        let rs = await axios.get(url);
        state.communicationCounts = rs.data;
      } catch (err) {
        toast.error('Failed to load communication counts');
        console.error(`Failed to load my gateway list for me.  Error was ${err}`);
      }
    },

    async sendInvite({state, rootState}, payload) {
      let current_user_id = rootState.auth.user.user.Auth.system_user_id;
      let url = rootState.settings.api_base + state.url_invite + current_user_id;
      console.log('url', url);
      let res;
      try {
        res = await axios.post(url, payload.data);
        if (res.data.status.code === 200){
          toast.success(res.data.status.result);
        } else {
          toast.error(res.data.status.result);
        }
      } catch (err) {
        console.error('users store sendInvite', err);
        toast.error('Unable to send invite. Please try again later.');
      }
      return res;
    },

    async clickBookmark({ state, commit }, sensorId) {
      const bookmark = getBookmarkedSensor(state, sensorId);
      if (!bookmark || bookmark.deleted) {
        commit('addBookmark', sensorId);
        return true;
      } else {
        commit('deleteBookmark', sensorId);
        return false;
      }
    },

    async getBookmarkedSensors({commit, rootState}) {
      let url = rootState.settings.api_base + 'bookmarks';
      try {
        let res = await axios.get(url);
        commit('setBookmarks', res.data);
        if (res.status !== 200) {
          toast.error("Error fetching bookmarks");
        }
      } catch (err) {
        console.error('getBookmarkedSensors error:', err);
      }
    },

    async saveBookmarkedSensors({state, rootState}) {
      let url = rootState.settings.api_base + 'bookmarks';
      try {
        let res = await axios.post(url, {bookmarks: state.bookmarkedSensors});
        if (res?.status !== 200) {
          toast.error("Error saving bookmarks");
        }
      } catch (err) {
        console.error('saveBookmarkedSensors error:', err);
      }
    }
  }
}
