<template>
  <select class="form-control-sm" style="min-width: 100%; max-width: 100%"
          @input="setSelected" v-model="dropdownValueList"
          :disabled="disabled" :data-help="help">
    <option v-if="showDefaultValue" :value="defaultEmptyValue">
      {{ defaultEmptyValueMessage }}
    </option>
    <option :value="arr[valueField]" :key="arr[valueField]" v-for="arr in dropdownList">
      {{ arr[descriptionField] }}
    </option>
  </select>
</template>

<script>
import { useStore } from 'vuex'
import {onMounted, computed} from 'vue'
export default {
  props: {
    listType: String,
    table: String,
    valueField: String,
    descriptionField: String,
    showDefaultValue: Boolean,
    defaultEmptyValue: String,
    defaultEmptyValueMessage: String,
    idx: Number,
    type: String,
    condition: String,
    value: String,
    disabled: Boolean,
    help: String
  },
  setup(props, context) {
    let _idx = props.idx || 0;
    let store = useStore();

    onMounted(() => {
      // populate dropdown list
      console.log('ApgDropdown - loadDropDown - props are', {...props})
      if (props.type == "registerToRead") {
        store.dispatch('apgList/loadDropdownWhereCondition', {
          table: props.table,
          valueField: props.valueField,
          descriptionField: props.descriptionField,
          condition: props.condition,
          listType: props.table,
        })
      } else {
        store.dispatch('apgList/loadDropdown', {
          table: props.table,
          valueField: props.valueField,
          descriptionField: props.descriptionField,
          listType: props.listType,
        });
      }

      // make sure if we already have a value that it is selected
      if (props.value && props.value !== 'false') {
        store.commit('apgList/setDropdownValue', {
          idx: 0,
          table: props.table,
          listType: props.listType,
          data: props.value
        });
      }
    });

    let _listType = props.listType || props.table;

    if(!(_listType in store.state.apgList.dropdown_values)) {
      store.state.apgList.dropdown_values[_listType] = [];
    }

    function setSelected(e) {
      let _listType = props.listType || props.table;
      console.log('Setting selected dropdown item ' + _listType + ' = ' + e.target.value + ' at index ' + _idx);
      context.emit('DropdownChanged', e.target.value);
      store.commit('apgList/setDropdownValue', {
        table: props.table,
        data: e.target.value,
        listType: _listType,
        idx: _idx,
      });
    }

    let dropdownList = computed(() => {
      return store.state.apgList.dropdowns[props.table]
    });

    let dropdownValueList = computed(() => {
      return store.state.apgList.dropdown_values[_listType][_idx]
    });

    return {
      dropdownList,
      setSelected,
      dropdownValueList
    }
  },
}
</script>

<style scoped>
option {
  background-color: var(--color-bg-sec);
}
</style>
