<template>
  <div class="row">
    <div class="col-md-4">
      <label> {{ name }} </label> 
    </div>
    <div class="col-md-8">
      <input class="form-control-sm" type="datetime-local"
             :id="id"
             :disabled="disabled"
             :value="formatDate(value)"
             :data-help="help"
             @input="change(attId, attInfoId, ownerId, listType, $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import moment from 'moment'
  export default {
    name: "AttributeDate",
    components: {},
    props: {
      id: String,
      value: String,
      name: String,
      attId: Number,
      disabled: Boolean,
      attInfoId:Number,
      ownerId: Number,
      listType: String,
      help: String,
    },
    setup() {
      const store = useStore();
      const change = function (id, attInfoId, ownerId, listType, value) {
        console.log('index is ', id)
        console.log('value is ', value)

        store.dispatch('user/upsertAttribute', {
          attribute_value: value,
          attribute_info_id: attInfoId,
          owner_id: ownerId,
          list_type: listType
        });
      }
      return{
        change,
      }
    },
    methods: {
      formatDate: function(value){
        if (!isNaN(value)) {
          return moment(new Date(parseInt(value))).format("YYYY-MM-DDTHH:MM");
        } else {
          return value;
        }
        
      }
    },     
  };
</script>

<style scoped>
  input {
    width:100%;
  }
</style>