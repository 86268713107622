import { Popover } from 'bootstrap';

function wrap(field) {
  if (field._wrapped) return;
  field._wrapped = true;

  // Helper to create the help button with common settings
  function createHelpButton() {
    const btn = document.createElement('button');
    btn.type = 'button';
    btn.classList.add('help-btn', 'help-icon-btn');
    btn.setAttribute('data-bs-toggle', 'popover');
    btn.setAttribute('data-bs-trigger', 'hover focus');
    btn.setAttribute('data-bs-placement', 'right');
    btn.setAttribute('data-bs-html', 'true');
    btn.setAttribute('aria-label', 'Show help');
    btn.innerHTML = '<i class="bi bi-question-circle-fill"></i>';
    return btn;
  }

  let wrapper;
  // Check if the field is part of a switch widget
  const switchContainer = field.closest('.form-switch');
  if (switchContainer) {
    if (switchContainer._wrapped) return;
    switchContainer._wrapped = true;

    // Create a new wrapper for switch fields
    wrapper = document.createElement('div');
    wrapper.classList.add('form-switch-wrapper');
    switchContainer.parentNode.replaceChild(wrapper, switchContainer);
    wrapper.append(switchContainer);
  } else {
    // Default wrapper for other inputs
    wrapper = document.createElement('div');
    wrapper.classList.add('base-input-field');
    field.replaceWith(wrapper);
    wrapper.append(field);
  }

  // Create and append the help button
  const btn = createHelpButton();
  wrapper.append(btn);

  // Initialize the Popover with the help content
  new Popover(btn, {
    content: field.dataset.help || '',
    html: true,
    sanitize: true
  });
}

function processNode(node) {
  if (node.nodeType !== Node.ELEMENT_NODE) return;
  if (node.matches(
    'input[data-help], ' +
    'select[data-help], ' +
    'textarea[data-help], ' +
    'label[data-help],' +
    'h5[data-help],' +
    'p[data-help]'
  )) {
    wrap(node);
  }

  node.querySelectorAll(
    'input[data-help], ' +
    'select[data-help], ' +
    'textarea[data-help],' +
    'label[data-help],' +
    'h5[data-help],' +
    'p[data-help]'
  ).forEach(wrap);
}

function updatePopover(field) {
  const wrapper = field.closest('.base-input-field');
  if (!wrapper) return;
  const btn = wrapper.querySelector('.help-btn');
  const pop = Popover.getInstance(btn);
  const content = field.dataset.help || '';
  btn.dataset.bsContent = content;
  if (pop) pop.setContent({ '.popover-body': content });
}

export default {
  install() {
    // Initial wrap in idle time
    const init = () => processNode(document.body);
    if ('requestIdleCallback' in window) requestIdleCallback(init);
    else setTimeout(init, 0);

    // Watch for new fields AND data-help changes
    const observer = new MutationObserver(mutations => {
      for (const m of mutations) {
        if (m.type === 'childList') {
          m.addedNodes.forEach(processNode);
        }
        if (m.type === 'attributes' && m.attributeName === 'data-help') {
          updatePopover(m.target);
        }
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['data-help']
    });
  }
};
