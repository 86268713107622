<template>
  <div class="row">
    <div class="col-md-6">
      <label class="form-check-label" for="flexSwitchCheckDefault">{{ name }}</label>
    </div>
    <div class="col-md-6">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" :id="id" :disabled="disabled"
               :checked="value === 'true' || value === '1'"
               :data-help="help"
               @input="change(attId, attInfoId, ownerId, listType, $event.target.checked, id)"/>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";

export default {
  name: "AttributeBoolean",
  props: {
    id: String,
    value: String,
    name: String,
    attId: Number,
    disabled: Boolean,
    attInfoId: Number,
    ownerId: Number,
    listType: String,
    help: String,
  },
  setup() {
    const store = useStore();

    const change = function (id, attInfoId, ownerId, listType, value, elementId) {
      if (attInfoId == 9198) {
        // if the attribute is a gateway status update, we need to confirm the user wants to add/remove it
        if (confirm(`You are about to ${value === true? 'add' : 'remove'} a gateway from our account. Please confirm.`)) {
          store.dispatch("gateway/updateGatewayStatus", {value: value}).then(() => {
            console.log('Changed attribute complete');
          });
        } else {
          // Reset the checkbox if cancel is clicked
          document.getElementById(elementId).checked = !value;
        }
      } else {
        // just a regular attribute update, no confirmation needed
        store.dispatch('user/upsertAttribute', {
          attribute_value: value,
          attribute_info_id: attInfoId,
          owner_id: ownerId,
          listType
        });
      }
    }
    return {
      change,
    }
  },
};
</script>

<style scoped>
.form-check {
  padding-left: 12px !important;
}
</style>
