<template>
  <div class="card" style="margin-top: 20px">
    <div class="card-header">Sensor Attributes</div>
    <div class="card-block">
      <ul class="list-group">
        <SensorAttributeItem
          :value="item.attribute_value"
          :key="index"
          :keyName="item.attribute_key" 
          :idx="index"
          v-for="(item, index) in sensorAttributeList"
          :type="item.attribute_type"
          :attId="parseInt(item.attribute_id)"
          :attInfoId="parseInt(item.attribute_info_id)"
          :ownerId="parseInt(item.owner_id)"
          :max="parseInt(item.max)"
          :min="parseInt(item.min)"
          :help="item.help"
        />
      </ul>
      <div
        class="d-flex align-items-center justify-content-center noItemsSpan"
        v-if="!hasItems"
      >
        No items found for selection
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import SensorAttributeItem from "./sensor-attribute-item.vue";
export default {
  name: "sensorAttributeList",
  components: {
    SensorAttributeItem,
  },
  props: {
      source: String,
      usage:String,
    },
  setup(props) {
    const store = useStore();
    return {
      sensorAttributeList: computed(() => store.state.apgList.List['sensor_attribute']),
      hasItems: computed(() => {
          return props.usage == "list"
            ? typeof store.state.apgList.List['sensor_attribute'] !== "undefined" &&
                store.state.apgList.List['sensor_attribute'].length > 0
            : typeof store.state.apgList.Available['sensor_attribute'] !==
                "undefined" &&
                store.state.apgList.Available['sensor_attribute'].length > 0;
        }),
    };
  },
};
</script>
<style scoped>
  .noItemsSpan {
    height: 200px;
    width: 100%;
    background-color: aliceblue;
  }
</style>