<template>
  <div class="row">
    <div class="col-md-4">
      <label> {{ name }} </label>
    </div>
    <div class="col-md-8">
      <input
          class="form-control-sm"
          type="text"
          :id="id"
          :disabled="disabled"
          :value="value"
          :data-help="help"
          @change="change(attId, attInfoId, ownerId, listType, $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import {useToast} from 'vue-toastification';
import nerdamer from 'nerdamer/all.min';

  export default {
    name: "AttributeText",
    components: {},
    props: {
      id: Number,
      value: String,
      name: String,
      attId: Number,
      disabled: Boolean,
      attInfoId:Number,
      ownerId: Number, 
      listType: String,
      help: String,
    },
    setup() {
      const store = useStore();
      const toast = useToast();
      const change = function (id, attInfoId, ownerId, listType, value) {
        console.log('index is ', id)
        console.log('value is ', value)
        console.log('owner is ', ownerId)

        if (attInfoId == 9166 && !checkExpression(value)) {  // register extra math check
          toast.error('Please enter a valid math expression that includes x (the sensor value)');
          console.error('Math expression is invalid');
          return;
        }

        store.dispatch('user/upsertAttribute', {
          attribute_value: value,
          attribute_info_id: attInfoId,
          owner_id: ownerId,
          list_type: listType,
        });
      }

      const checkExpression = function (expression) {
        try {
          if (!expression.includes('x')) {
            return false;
          }
          nerdamer(expression).evaluate( {x: 1} );
          nerdamer.flush();
          return true;
        } catch (error) {
          console.log('error is ', error)
          nerdamer.flush();
          return false;
        }
      }

      return {
        change,
      }
    },
  };
</script>

<style scoped>
  input {
    width:100%;
  }
</style>