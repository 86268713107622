import axios from 'axios'
import {useToast} from 'vue-toastification';

const toast = useToast();

export const gateway = {
  namespaced: true,

  state: {
    url_part: 'gateways',
    url_gateway: 'gateway/info',
    url_gateway_sales: 'gateway/sales/info',
    url_gateways_for_group: 'gateways/group/',
    url_gateways_for_group_me: 'group/gateways/me/',
    url_remove_gateway_from_group: 'gateway/remove_group/',
    url_add_gateways_to_group: 'group/add_gateways/',
    url_group_for_gateway: 'groups/gateway/',
    url_gateways_reading_control: 'gateways/readingControl/',
    url_gateways_GPS_location: 'gateways/GPSLocation/',
    url_gateways_function_code: 'gateways/changeFunction/',
    url_gateways_update_GCR: 'gateways/updateGCR/',
    url_gateways_me: 'gateways/me/',
    url_gateways_queued_message: 'gateways/queuedMessage/',
    url_gateways_sent_message: 'gateways/sentMessage/',
    url_gateways_send_message: 'gateways/sendMessage/',
    url_gateways_alarms: 'gateways/Alarm/',
    url_remove_message: 'gateways/removeMessage/',
    url_gateway_overview: 'gateways/overview/',
    url_gateway_timings: 'gateways/timings/',
    url_gateway_registration: 'gateways/register',
    url_gateway_clean: 'gateways/clean',
    readings: {},
    type: null,
    id: null,
  },
  mutations: {
    async setId(state, {id, load = true}) {
      state.id = id;
      if (load === true) {
        await this.dispatch('gateway/findInfoOverview', {id: id});
        await this.dispatch('gateway/findQueuedMessages', {id: id});
        await this.dispatch('gateway/findSentMessages', {id: id});
        await this.dispatch('gateway/findOne', {listType: 'gateway_attribute', id: id});
        await this.dispatch('readingControl/findGatewayTimings', {id: id});
        await this.dispatch('gateway/findGatewayGPSLocation', {id: id});
        await this.dispatch('readingControl/getFunctions');
        await this.dispatch('readingControl/findGatewayReadingControl', {id: id});
        await this.dispatch('alarm/fetchAlarms', {id: id});
      }
    },
    loadReadings(state, payload) {
      payload.forEach((reading) => {
        if (!state.readings[reading.sensor_id]) {
          state.readings[reading.sensor_id] = {};
        }
        if (!state.readings[reading.sensor_id][reading.sensor_address]) {
          state.readings[reading.sensor_id][reading.sensor_address] = [];
        }
        state.readings[reading.sensor_id][reading.sensor_address].push({
          receive_datetime: reading.receive_datetime,
          sensor_data_value: reading.sensor_data_value,
        });
      });
    },
    clearReadings(state) {
      state.readings = {};
    },
    setType(state, type) {
      state.type = type;
    }
  },
  actions: {
    async findAll({rootState, state, commit}, payload) {
      let user = JSON.parse(localStorage.getItem('user'))
      try {
        let rs = await axios.get(rootState.settings.api_base + state.url_part, {
          params: {user_id: user.user.system_user_id},
        })
        console.log('Gateway response was ', rs)
        commit('apgList/setLists', {listType: payload.listType, data: rs.data}, {root: true})
      } catch (err) {
        console.error('Failed to load gateway list.  Error was ', err)
      }
    },
    async findQueuedMessages({rootState, state, commit,}, payload) {
      let id = payload.id;
      console.log('find all queued message(s) for ', id);
      let url = rootState.settings.api_base + state.url_gateways_queued_message + id;

      try {
        let rs = await axios.get(url);
        console.log('found queued message ', rs.data);
        commit('apgList/setList', {listType: 'gateway_queued_message', data: rs.data.queuedMessage}, {root: true});
      } catch (err) {
        console.error('Failed to retrieve queued message(s).  Error was ', err);
      }
    },

    async findSentMessages({rootState, state, commit}, payload) {
      console.log('find all sent message(s) for ', payload.id);
      let url = rootState.settings.api_base + state.url_gateways_sent_message + payload.id;
      console.log('url ', url);
      try {
        let rs = await axios.get(url);
        console.log('found sent message ', rs.data);
        commit('apgList/setList', {listType: 'gateway_sent_message', data: rs.data.sentMessage}, {root: true});
      } catch (err) {
        console.error('Failed to retrieve sent message(s).  Error was ')
        console.error({err})
      }
    },

    async setSentRetries({rootState, dispatch}, payload) {
      let gatewayId = rootState.gateway.id;
      let url = `${rootState.settings.api_base}gateways/retryMessage/${gatewayId}/${payload.messageId}/${payload.retries}`;
      console.log('url ', url);

      try {
        await axios.patch(url);
        toast.success('Retries updated!');
        dispatch('findSentMessages', {id: gatewayId});
      } catch (err) {
        console.error('Failed to update retries.  Error was ', err);
        toast.error('Unable to update retries. Please try again later.');
      }
    },

    async findGatewayGPSLocation({rootState, state, commit}, payload) {
      let id = payload.id;
      console.log('find all gateways GPS Location for ', id);
      let url = rootState.settings.api_base + state.url_gateways_GPS_location + id;
      try {
        let rs = await axios.get(url);
        console.log('found gateways GPS Location ', rs.data);
        commit('apgList/setList', {listType: "gateway_GPS_Location", data: rs.data.Info}, {root: true});
      } catch (err) {
        console.error('Failed to load gateway GPS Location.  Error was ');
        console.error({err});
      }
    },

    async findOne({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_gateway + '/' + payload.id;
      console.log(url);
      try {
        let rs = await axios.get(url)
        console.log('gateway info is', rs);
        // set type
        commit('apgList/setList', {listType: payload.listType, data: rs.data.Attributes}, {root: true});
      } catch (err) {
        console.error(`Failed to load user info.  Error was ${err}`);
      }
    },

    async findAllForGroup({rootState, state, commit}, payload) {
      console.log('find all gateways for group ', rootState.apgList.ids['group'].group_id)
      let url = rootState.settings.api_base + state.url_gateways_for_group + rootState.apgList.ids['group'].group_id;
      try {
        let rs = await axios.get(url)
        console.log('found gateways list for group', rs.data)
        if (typeof payload !== 'undefined') {
          commit('apgList/setLists', {listType: payload.listType, data: rs.data}, {root: true})
        } else {
          return rs.data
        }
      } catch (err) {
        console.error('Failed to load gateway info for group.  Error was ')
        console.error({err})
      }
    },

    async findInfoOverview({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_gateway_overview + payload.id;
      try {
        let rs = await axios.get(url);
        commit('setType', rs.data.Attributes.find(a => a.attribute_name === 'gatewayType').attribute_value);
        commit('apgList/setList', {listType: "gateway_overview", data: rs.data.Attributes}, {root: true});
      } catch (err) {
        console.error('Failed to load my gateway overview.  Error was ');
        console.error({err});
      }
    },

    async findAllForGroupMe({rootState, state, commit}, payload) {
      let group_id = payload.group_id
      console.log('find all gateways for group ' + group_id)
      let url = rootState.settings.api_base + state.url_gateways_for_group_me + group_id

      try {
        let rs = await axios.get(url)
        console.log('found gateways list for group ' + group_id, rs.data)
        if (typeof payload !== 'undefined') {
          commit('MyView/addChildrenToParent', {
            listType: payload.listType,
            idx: payload.idx,
            data: rs.data
          }, {root: true})
        }
      } catch (err) {
        console.error('Failed to load my gateway info for group.  Error was ')
        console.error({err})
      }
    },

    async findAllForMe({rootState, state, commit}) {
      let current_user_id = rootState.auth.user.user.Auth.system_user_id
      console.log('find all gateways for current user ' + current_user_id)
      let url = rootState.settings.api_base + state.url_gateways_me + current_user_id
      try {
        let rs = await axios.get(url)
        console.log('found gateways list for me ' + current_user_id, rs.data)
        commit('apgList/setList', {listType: 'gateway_me', data: rs.data.List}, {root: true})
      } catch (err) {
        console.error('Failed to load my gateway list for me.  Error was ')
        console.error({err})
      }
    },

    async removeGatewayFromGroup({rootState, state, dispatch}, payload) {
      let url = rootState.settings.api_base + state.url_remove_gateway_from_group +
        payload.gatewayId + '/' + rootState.apgList.ids['group'].group_id;
      console.log('removing gateway url is', url);

      try {
        await axios.delete(url);
        dispatch('findAllForGroup', payload);
        toast.success('Removed gateway from group!');
      } catch (err) {
        console.error('Failed to remove gateway from group.  Error was ', err);
        toast.error('Unable to remove gateway from group. Please try again later.');
      }
    },
    async removeGroupFromGateway({rootState, state, dispatch}) {
      let url =
        rootState.settings.api_base +
        state.url_remove_gateway_from_group +
        state.id +
        '/' +
        rootState.apgList.ids['gateway_group'].group_id
      console.log('removing group from Gateway url is', url);
      try {
        await axios.delete(url);
        dispatch('findAllForGateway', {listType: 'gateway_group'});
        toast.success('Removed group from gateway!');
      } catch (err) {
        console.error('Failed to remove gateway from group.  Error was ');
        console.error({err});
        toast.error('Unable to remove group from gateway. Please try again later.');
      }
    },
    async findAllForGateway({rootState, state, commit}, payload) {
      let id = state.id ?? payload.id;
      let url = rootState.settings.api_base + state.url_group_for_gateway + id;

      try {
        let rs = await axios.get(url)
        console.log('found groups list for gateway', rs.data)
        commit('apgList/setLists', {listType: payload.listType, data: rs.data}, {root: true})
      } catch (err) {
        console.error('Failed to load group list for gateway.  Error was ')
        console.error({err})
      }
    },

    async sendMessage({state, dispatch, commit, rootState}, payload) {
      let url = rootState.settings.api_base + state.url_gateways_send_message + payload.id;
      console.log('url ', url);
      try {
        await axios.patch(url);
        await dispatch('findQueuedMessages', payload);
        await dispatch('findSentMessages', payload);
        await commit('MyView/setGatewayPendingMessages', {gatewayId: payload.id, messageCount: 0}, {root: true});
        toast.success('Messages sent to gateway!');
      } catch (err) {
        console.log("removing message: error was: ", err);
        toast.error('Unable to send messages to gateway. Please try again later.');
      }
    },

    async removeMessage({state, dispatch, commit, rootState}, payload) {
      let url = rootState.settings.api_base + state.url_remove_message + payload.send_data_queue_id;
      console.log('url ', url)
      try {
        await axios.patch(url, {})
        await dispatch('findQueuedMessages', payload);
        await dispatch('findInfoOverview', {id: rootState.gateway.id});
        await commit('MyView/decrementGatewayPendingMessages', {gatewayId: payload.id}, {root: true});
      } catch (err) {
        console.log("removing message: error was: ", err)
      }
    },

    async addGatewaysToGroup({state, dispatch, rootState, commit}, payload) {
      let checked = rootState.apgList?.checked?.[payload.listType];
      if (!checked || checked.length === 0) {
        return;
      }

      let gateway_list = checked.join('-');
      let gtwy_str = checked.length > 1 ? 'gateways' : 'gateway';
      console.log('gateway list is ', gateway_list)
      let url =
        rootState.settings.api_base +
        state.url_add_gateways_to_group +
        rootState.apgList.ids['group'].group_id +
        '/' +
        gateway_list
      console.log('url ', url)
      try {
        let rs = await axios.put(url, {});
        console.log('addGatewaystoGroup', rs);
        commit('apgList/clearChecked', payload, {root: true});
        dispatch('findAllForGroup', payload);
        toast.success(`Added ${gtwy_str} to group!`);
      } catch (err) {
        console.log('addGatewaysToGroup: error was: ', err);
        toast.error(`Unable to add ${gtwy_str} to group. Please try again later.`);
      }
    },

    async createRawMessage({commit}, payload) {
      let rawMsgForm = {
        command_pretty_name: 'User Created Raw Message',
        last_update_ts: payload.ts,
        last_update_user_id: payload.user_id,
        username: payload.username,
        gateway_id: payload.gateway_id,
      }
      commit('apgList/addNew', {listType: 'gateway_queued_message', data: rawMsgForm}, {root: true});
    },
    async saveRawMessage({rootState, state, dispatch, commit}, payload) {
      let url = rootState.settings.api_base + state.url_gateways_queued_message + payload.gateway_id;
      console.log('save raw msg', url);

      try {
        await axios.post(url, {...payload});
        await dispatch('findQueuedMessages', {id: payload.gateway_id});
        await dispatch('findInfoOverview', {id: payload.gateway_id});
        await commit('MyView/addGatewayPendingMessages', {gatewayId: payload.gateway_id}, {root: true});
      } catch (e) {
        console.error('save raw message: error:', e);
      }
    },
    async registerSwarmDevice({rootState, state, dispatch}, payload) {
      let rawString = JSON.parse(payload.decoded ?? '');
      let authCode = rawString?.ac ?? '';

      let url = rootState.settings.api_base + state.url_gateway_registration;
      console.log('url', url);
      try {
        let res = await axios.post(url,
          {
            ac: authCode,
            user_id: rootState.auth.user.user.Auth.system_user_id,
          });

        // update list of gateways
        dispatch('findAll', {listType: 'gateway'});
        return res.status === 200;
      } catch (e) {
        console.error('register new swarm device:', e);
        return false;
      }
    },
    async updateGatewayStatus({rootState, state}, payload) {
      let url = rootState.settings.api_base + state.url_gateway_registration;
      console.log('url', url);

      try {
        let res = await axios.patch(url, {
          gatewayId: state.id,
          userId: rootState.auth.user.user.Auth.system_user_id,
          status: payload.value,
        });

        // toast
        if (res.status === 200) {
          toast.success(`Gateway status successfully ${payload.value === true ? 'updated!' : 'removed!'}`);
        } else {
          toast.error('Failed to update gateway status. Please try again later.');
        }
      } catch (e) {
        toast.error('Failed to update gateway status. Please try again later.');
        console.error('update gateway status:', e);
      }
    },

    async cleanGateway({rootState, state}, payload) {
      let gatewayId = payload?.id ?? state.id;
      let url = rootState.settings.api_base + state.url_gateway_clean + gatewayId;
      console.log('url', url);
      try {
        let rs = await axios.post(url);
        if (rs.status === 200) {
          toast.success('Gateway cleaned!');
        } else {
          toast.error('Failed to clean gateway. Please try again later.');
        }
      } catch (e) {
        console.error('Failed to clean gateway.  Error was ', e);
        toast.error('Failed to clean gateway. Please try again later.');
      }
    },

    async getGatewayReadings({rootState, commit}, payload) {
      let url = rootState.settings.api_base + 'gateway/readings/' + payload.id;
      console.log('url', url);
      try {
        let rs = await axios.get(url);
        console.log('found gateway readings ', rs.data);
        await commit('loadReadings', rs.data);
      } catch (e) {
        console.error('Failed to load gateway readings.  Error was ', e);
      }
    }
  },

  getters: {
    getReadings: (state) => {
      return state.readings;
    },
  }
}
