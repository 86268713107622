export const MyView = {
  namespaced: true,
  state: {
    data: {
      group_gateway_sensor: [{id: 0, label: "No Data Found", nodes: []},],
    },
    user_id: 0,
    selected: {
      group: {
        id: null,
      },
      gateway: {
        id: null,
      },
      sensor: {
        id: null,
      }
    }
  },

  actions: {
    async click({ commit, dispatch, getters }, { id, level, toggle=false }) {
      await this.commit('settings/setIsLoading', true);
      this.commit('settings/setIgnore', true);

      // find the node
      let node = getters['findNode'](id, level);
      if (node == null) {
        console.error('Node not found', id, level);
        this.commit('settings/setIgnore', false);
        await this.commit('settings/setIsLoading', false);
        return;
      }
      // we want to expand the group if this is an 'automated' click; however, we need to support the user manual collapsing
      node.expanded = !node.expanded || !toggle;
      // set the selected node
      await commit('setSelected', { level:level, id: node.id });

      // handle the specific level click
      if (level === 'group') {
        await dispatch('clickGroup', { node });
      } else if (level === 'gateway') {
        await dispatch('clickGateway', { node });
      } else if (level === 'sensor') {
        await dispatch('clickSensor', { node });
      }

      await commit('saveState');
      await this.commit('settings/setIsLoading', false);
    },

    async clickGroup({ commit }, { node }) {
      await this.commit('settings/setIsLoading', true);

      // clear any selected gateways or sensors
      await commit('clearSelected', { level: 'gateway' });
      await commit('clearSelected', { level: 'sensor' });

      // fetch data
      await this.dispatch('gateway/findAllForGroupMe', {group_id: node.id,idx: node.idx,listType: 'group_gateway_sensor'});
      await this.commit('apgList/setID',{ listType: 'group', idType: 'group_id', _id: node.id });
      await this.commit('settings/setIsLoading', false);
      this.commit('settings/setIgnore', false);
    },

    async clickGateway({commit, getters}, {node}) {
      await this.commit('settings/setIsLoading', true);
      this.commit('settings/setIgnore', true);

      // clear any selected sensors
      await commit('clearSelected', {level: 'sensor'});

      // update selected group
      let parentNode = getters['findParent'](node.id, node.level);
      parentNode.expanded = true;
      await commit('setSelected', {level: 'group', id: parentNode.id});
      await this.commit('gateway/setId', {id: node.id});

      // fetch data
      await this.commit('apgList/clearLists');
      await this.commit('chart/clearData', {listType: 'group_gateway_sensor'});
      await this.commit('gateway/clearReadings');

      await this.dispatch('sensor/findAllForGatewayMe', {
        id: node.id,
        idx: node.idx,
        idx2: node.idx2,
        listType: 'group_gateway_sensor'
      });
      await this.dispatch('gateway/getGatewayReadings', {id: node.id});
      await this.commit('settings/setIsLoading', false);
      this.commit('settings/setIgnore', false);
    },

    async clickSensor({ commit, getters }, { node }) {
      this.commit('settings/setIsLoading', true);
      this.commit('settings/setIgnore', true);

      // update selected parent gateway
      let parentNode = getters['findParent'](node.id, node.level);
      parentNode.expanded = true;
      commit('setSelected', { level: 'gateway', id: parentNode.id });

      // update selected grandparent group
      let grandParentNode = getters['findParent'](parentNode.id, 'gateway');
      grandParentNode.expanded = true;
      commit('setSelected', { level: 'group', id: grandParentNode.id });

      // fetch data
      this.commit('chart/clearData', { listType: 'group_gateway_sensor' });
      await this.dispatch('sensor/setId', {id: node.id});

      this.commit('settings/setIsLoading', false);
      this.commit('settings/setIgnore', false);
    },

    async drillDown({ dispatch }, payload) {
      const levels = ['group', 'gateway', 'sensor'];
      for (const level of levels) {
        if (payload[level] != null) {
          await dispatch('click', {
            id: payload[level],
            level: level
          });
        }
      }
    }
  },

  mutations: {
    addParents(state, payload) {
      payload.data.List.forEach(function (item, idx) {
        payload.data.List[idx] = {...item, idx, nodes: [{id: 0, label: 'No Data Found', level: 'group', nodes: []}]}
      })
      state.data[payload.parent] = payload.data.List;
    },
    addChildrenToParent(state, payload) {
      payload.data.List.forEach(function (item, idx) {
        payload.data.List[idx] = {
          ...item,
          idx2: idx,
          idx: payload.idx,
          groupIndex: payload.idx,
          gatewayIndex: idx,
          nodes: [{id: 0, label: 'No Data Found', level: 'gateway', nodes: []}]
        }
      });
      state.data[payload.listType][payload.idx]['nodes'] = payload.data.List;
    },
    addChildrenToChild(state, payload) {
      payload.data.List.forEach(function (item, idx) {
        payload.data.List[idx] = {
          ...item,
          idx2: idx,
          idx: payload.idx,
          sensorIndex: idx,
          gatewayIndex: payload.idx2,
          groupIndex: payload.idx
        }
      });
      state.data[payload.listType][payload.idx]['nodes'][payload.idx2]['nodes'] = payload.data.List;
    },
    setGatewayPendingMessages(state, { gatewayId, messageCount }) {
      let gateway = this.getters['MyView/getGateway'](gatewayId);
      if (gateway) {
        gateway.message_queue_count = parseInt(messageCount);
      }
    },
    addGatewayPendingMessages(state, { gatewayId }) {
      let gateway = this.getters['MyView/getGateway'](gatewayId);
      if (gateway) {
        gateway.message_queue_count = parseInt(gateway.message_queue_count) + 1;
      }
    },
    decrementGatewayPendingMessages(state, { gatewayId }) {
      let gateway = this.getters['MyView/getGateway'](gatewayId);
      if (gateway) {
        gateway.message_queue_count = parseInt(gateway.message_queue_count) - 1;
      }
    },
    setGatewayAlarmCount(state, { gatewayId, alarmCount }) {
      let gateway = this.getters['MyView/getGateway'](gatewayId);
      if (gateway) {
        gateway.alarm_count_active = parseInt(alarmCount);
      }
    },
    setSelected(state, { level, id }) {
      state.selected[level].id = id
    },
    clearSelected(state, { level }) {
      state.selected[level].id = null
    },
    clearAll(state) {
      state.selected.group.id = null;
      state.selected.gateway.id = null;
      state.selected.sensor.id = null;
    },
    loadState(state, savedState) {
      state.selected = savedState.selected;
      state.data = savedState.data;
    },
    saveState(state) {
      history.pushState({
        back: null,
        current: '/gateways',
        position: history.state.position,
        replaced: false,
        vuexState: JSON.parse(JSON.stringify({
          selected: state.selected,
          data: state.data
        }))}, null, '');
    }
  },

  getters: {
    getGateway: (state) => (id) => {
      for (let group of state.data.group_gateway_sensor) {
        let gateway = group.nodes.find(gateway => gateway.id === parseInt(id));
        if (gateway) {
          return gateway;
        }
      }
    },
    findNode: (state) => (id, level) => {
      // recursively find a node by id and level
      const searchNode = (node, id, level) => {
        if (node.id == id && node.level == level) {
          return node;
        }
        for (let child of node.nodes || []) {
          let found = searchNode(child, id, level)
          if (found) {
            return found;
          }
        }
      };
      // search for node in each group
      for (let group of state.data.group_gateway_sensor) {
        let found = searchNode(group, id, level)
        if (found) {
          return found;
        }
      }
      return null;
    },
    findParent: (state) => (childId, childLevel) => {
      // recursive function to search for a child's parent
      const searchChildren = (node, childId, childLevel) => {
        for (let child of node.nodes || []) {
          if (child.id == childId && child.level == childLevel) {
            return node
          }
          if (child.nodes) {
            let found = searchChildren(child, childId, childLevel)
            if (found) {
              return found
            }
          }
        }
      };
      // use recursive function to search for child
      for (let group of state.data.group_gateway_sensor) {
        let found = searchChildren(group, childId, childLevel)
        if (found) {
          return found
        }
      }
      return null;
    }
  }
}