<template>
  <div class="container-fluid">
    <!-- Row 1: Bookmarked Sensors & Latest Communication -->
    <div class="row mb-4">
      <div class="col-md-6 mb-4 mb-md-0">
        <div class="card h-100">
          <div class="card-header">
            Bookmarked Sensors
          </div>
          <div class="card-body p-0 scrollable-table" style="max-height: 300px;">
            <div v-if="bookmarkLoading" class="d-flex justify-content-center align-items-center" style="height: 15vh; width: 100%;">
              <div class="spinner-border" role="status"/>
            </div>
            <div v-if="bookmarkedReadings.length === 0 && !bookmarkLoading" class="d-flex justify-content-center align-items-center" style="height: 15vh; width: 100%;">
              <p class="text-center"
                 data-help="You can bookmark sensors by clicking on the icon next to a sensor on the
                 <a href='/gateways'>My View</a> page.
                 <img src='help/bookmark.png' alt='Bookmark help' class='img-fluid sm-help-img'/>"
              >
                No bookmarked sensors found
              </p>
            </div>
            <table v-if="bookmarkedReadings.length > 0 && !bookmarkLoading" class="table table-bordered table-hover mb-0">
              <thead class="thead-light">
                <tr class="text-center">
                  <th>Sensor Name</th>
                  <th>Sensor Model</th>
                  <th>Gateway Name</th>
                  <th>Last Reading</th>
                  <th>Last Communication</th>
                </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(row, i) in bookmarkedReadings" class="reading-row" :key="i"
                  @click="click(row.sensor_id, row.gateway_id, row.system_group_id)"
              >
                <td>{{ row.sensorname ?? '' }}</td>
                <td>{{ row.sensormodel ?? '' }}</td>
                <td>{{ row.gatewayname ?? '' }}</td>
                <td> {{ getMathCol(row) }}</td>
                <td>{{ datetimeConversion(row.receive_datetime) ?? '' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-header">
            Latest Communication
          </div>
          <div class="card-body p-0 scrollable-table" style="max-height: 300px;">
            <div v-if="lastLoading" class="d-flex justify-content-center align-items-center" style="height: 15vh; width: 100%;">
              <div class="spinner-border" role="status"/>
            </div>
            <div v-if="lastReadings.length === 0 && !lastLoading" class="d-flex justify-content-center align-items-center" style="height: 15vh; width: 100%;">
              <p class="text-center">No sensor readings found</p>
            </div>
            <table v-if="lastReadings.length > 0 && !lastLoading" class="table table-bordered table-hover mb-0">
              <thead class="thead-light">
                <tr class="text-center">
                  <th>Sensor Name</th>
                  <th>Sensor Model</th>
                  <th>Gateway Name</th>
                  <th>Last Reading</th>
                  <th>Last Communication</th>
                </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(row, i) in lastReadings" class="reading-row" :key="i"
                  @click="click(row.sensor_id, row.gateway_id, row.system_group_id)"
              >
                <td>{{ row.sensorname ?? 'Not Set' }}</td>
                <td>{{ row.sensormodel ?? 'Not Set' }}</td>
                <td>{{ row.gatewayname ?? 'Not Set' }}</td>
                <td> {{ getMathCol(row) }}</td>
                <td>{{ datetimeConversion(row.receive_datetime) ?? '' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Row 2: Communication Status & Map -->
    <div class="row">
      <div class="col-md-4 mb-4 mb-md-0">
        <div class="card h-100">
          <div class="card-header">
            Communication counts for {{ moment(Date()).format('MMMM YYYY') }}
          </div>
          <!-- The scrollable-table class is applied here -->
          <div class="card-body p-0 scrollable-table" style="max-height: 525px;">
            <table class="table table-bordered table-hover mb-0">
              <thead class="thead-light">
              <tr>
                <th>Gateway</th>
                <th>Name</th>
                <th>Uplink</th>
                <th>Downlink</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="gateway in currentCommUsage" :key="gateway.gateway_id">
                <td>{{ gateway.gateway_name }}</td>
                <td>{{ gateway.name }}</td>
                <td>{{ gateway.downlink }}</td>
                <td>{{ gateway.uplink }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="card h-100">
          <div class="card-header">
            My Map with active gateways
          </div>
          <div class="card-body">
            <gateway-map />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GatewayMap from '@/components/shared/charts/GatewayMap'
import {computed, onMounted} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import moment from 'moment'
import nerdamer from 'nerdamer/all.min';

export default {
  components: {
    GatewayMap
  },
  name: "Home",
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(()=>{
      store.dispatch('gateway/findAllForMe');
      store.dispatch('user/fetchCommunicationCounts');
      store.dispatch('user/getLastReadings');
      store.dispatch('user/fetchBookmarkedReadings');
    });

    return {
      content: "",
      click: async (sensorId, gatewayId, groupId) => {
        await router.push({ name: 'gateways', query: { sensorId: sensorId, gatewayId: gatewayId, groupId: groupId } });
      },
      getMathCol: (row) => {
        if (row.extramath) {
          const computedValue = nerdamer(row.extramath, { x: row.sensor_data_value }).evaluate().text();
          if (computedValue != null && !isNaN(computedValue)) {
            return `${computedValue} ${row.units}`;
          }
        }
        return `${row.sensor_data_value ?? ''}`;
      },
      currentCommUsage: computed(() => store.state.user.communicationCounts),
      lastReadings: computed(() =>  store.state.user.lastReadings),
      bookmarkedReadings: computed(() => store.state.user.bookmarkedReadings),
      bookmarkLoading: computed(() => store.state.user.bookmarkedReadingsLoading),
      lastLoading: computed(() => store.state.user.lastReadingsLoading),
    }
  },
  methods: {
    moment: function () {
      return moment();
    },
    datetimeConversion: function (timestamp) {
      return moment.utc(timestamp).local().format('MMM Do YY, h:mm:ss a')
    }
},
}
</script>
<style scoped>
  .card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .card-header {
    font-weight: bold;
    font-size: 1.1rem;
    background-color: var(--color-sec);
  }

  .table-hover tbody tr:hover,
  .reading-row:hover {
    background-color: var(--color-border);
    cursor: pointer;
  }

  .reading-row {
    cursor: pointer;
  }
  .reading-row:hover > td {
    color: var(--color-sec);
  }
  .scrollable-table {
    overflow-y: auto;
  }
</style>
